<template>
  <div class="cs-pop" v-if="showCs">
    <div class="overlay" @click="openCS(false)"></div>
    <div class="cscover">
      <div @click="goCs(1)">{{ textArr[selectedLang]['cs'][0] }}</div>
      <div>
        <div @click="goCs(2)">{{ textArr[selectedLang]['cs'][1] }}</div>
        <span @click="randomCs()">{{ textArr[selectedLang]['cs'][2] }}</span>
      </div>
    </div>
  </div>
  <div class="container-header">
    <div style="display:none;">master</div>
    <div class="logo">
      <img :src="require('./assets/new-logo-white.svg')" alt />
    </div>
    <div class="container-header-actions" @click="openCS(true)">
      <div class="cs-icon"><img :src="require('./assets/cs.png')" alt /></div>
      {{ textArr[selectedLang]['header'][0] }}
    </div>
    <div class="container-header-lang">
      <div class="main">
        <span>{{ selectedLangData.name }}</span>
        <div>{{ selectedLangData.value }}</div>
        <!-- {{ selectedLang === 'cn' ? 'CHN' : (selectedLang === 'en' ? 'ENG' : 'IDN') }} -->
      </div>
      <div class="list">
        <div class="row" v-for="(lang, a) in languageData" :key="a">
          <img :src="require(`./assets/lang/${lang.img}.png`)" alt />
          <div class="name">{{ selectedLang === 'cn' ? lang.cn : lang.en }}</div>
          <div class="sub">
            <span :class="sub.lang === '' ? 'disabled' : ''" v-for="(sub, b) in lang.data" :key="b" @click="selectLang(lang, sub.id)">{{ sub.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-note">
    <div><span></span>{{ textArr[selectedLang]['note'][0] }}<span></span></div>
  </div>
  <div class="container-body" :style="[{backgroundImage: `url(${require('./assets/default-bg.jpg')})`}]">
    <video
        v-if="videoSource"
        class="video-bg"
        autoplay
        muted
        loop
        playsinline="true"
        webkit-playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5"
      >
        <source :src="videoSource" type="video/mp4" />
    </video>
    <div class="content">
      <div class="ticket-list">
        <swiper
          :slides-per-view="3"
          :space-between="10"
          :autoplay="true"
          :centered-slides="true"
          :loop="swiper_options.loop"
          :speed="swiper_options.speed"
        >
          <swiper-slide v-for="(game, k) in fakeData" :key="k">
            <div class="ticket-wrap" @click.stop="goFast">
              <div
                class="top"
                :style="{ backgroundImage: `url(${imgSource})` }"
              >
                <div class="name">
                  <img v-if="game.sport === 4" :src="require('./assets/basketball.svg')" alt />
                  <img v-else :src="require('./assets/foot.svg')" alt />
                  {{ game.league }}
                </div>
                <div class="team">
                  <div class="team-name"><span>{{ game.team1 }}</span></div>
                  <div class="vs-img"><img :src="require('./assets/vs.svg')" alt /></div>
                  <div class="team-name"><span>{{ game.team2 }}</span></div>
                </div>
              </div>
              <div
                class="bottom"
                :style="{ backgroundImage: `url(${bImgSource})` }"
              >
                <div class="note">
                  <div class="mark">
                    <img :src="require('./assets/mark.svg')" alt />
                    {{ game.method }}
                  </div>
                  <div class="auth">
                    {{ game.auth }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="return">
                  <div class="first">
                    <!-- 本金: {{ game.tb }},  -->
                    {{ textArr[selectedLang]['gold'][0] }}: {{ game.sb2 }}{{ textArr[selectedLang]['gold'][1] }}+<span>{{ textArr[selectedLang]['gold'][2] }}{{ game.sb }}{{ textArr[selectedLang]['gold'][1] }}</span>
                  </div>
                  <div class="last">
                    {{ textArr[selectedLang]['gold'][3] }}: {{ Number(game.sb2) + Number(game.sb) }}{{ textArr[selectedLang]['gold'][1] }}
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="ip-wrap" v-if="ip">
      <div class="text">
        <div class="desc">{{ textArr[selectedLang]['ip'][0] }}</div>
        <div class="ipshow">
          <div class="toggle" @click="openIP">{{ textArr[selectedLang]['ip'][1] }}<img :src="require('./assets/ip-arrow.svg')" alt /></div>
          <div class="ip" v-if="showIP">{{ textArr[selectedLang]['ip'][2] }} : {{ ip }}</div>
        </div>
      </div>
      <div class="refresh" @click="refresh"><img :src="require('./assets/refresh.svg')" alt /></div>
    </div>

    <div class="network-list">
        <div
          class="card elevation--z1"
          :style="{ color: fontColor }"
          v-for="(domainItem, key) in domainList"
          :key="key"
          @click="openNewDomain(key, domainItem)"
        >
          <div class="card-title" v-if="key === 0">{{ textArr[selectedLang]['list'][0] }}</div>
          <div class="card-title" v-else-if="key === 1">
            {{ textArr[selectedLang]['list'][1] }}
          </div>
          <div class="card-title" v-else-if="key === 2">{{ textArr[selectedLang]['list'][2] }}</div>
          <div class="card-title" v-else>
            {{ key > 2 ? textArr[selectedLang]['list'][3] : "" }}{{ textArr[selectedLang]['list'][4] }} {{ key + 1 }}
          </div>
          <div class="spacer">
            <div class="badge" v-if="domainItem.ping && key < 3">{{ textArr[selectedLang]['list'][5] }}</div>
          </div>
          <!-- <div class="card-status" :style="{color: fontColor}"> -->
          <div class="card-status">
            <div v-if="domainItem.ping && key === 0" style="white-space:nowrap">{{ textArr[selectedLang]['list'][6] }}</div>
            <template v-if="domainItem.ping">
              <div :class="getPingsCSS(domainItem.ping)">
                <div></div>
                <div></div>
                <div
                  v-if="
                    getFastStr(domainItem.ping) === '最快' ||
                      getFastStr(domainItem.ping) === '普通'
                  "
                ></div>
                <div v-if="getFastStr(domainItem.ping) === '最快'"></div>
              </div>
              <!-- <i class="mdi" :class="getPingsCSS(domainItem.ping)"></i> -->
              <!-- <span>{{domainItem.ping}} 毫秒</span> -->
            </template>
            <span v-else>{{ textArr[selectedLang]['list'][8] }}</span>
          </div>
          <div class="card-arrow"><img :src="require('./assets/network-arrow.svg')" alt /></div>
        </div>
      </div>
  </div>
  <div class="container-ios" v-if="isIOS">
    <img :src="require('./assets/logo.png')" />
    <div class="text">
      <span>{{ textArr[selectedLang]['ios'][0] }}"</span>
      <img :src="require('./assets/save.png')" />
      <span>",{{ textArr[selectedLang]['ios'][1] }}"</span>
      <b>{{ textArr[selectedLang]['ios'][2] }}</b>
      <span>"</span>
      <span>{{ textArr[selectedLang]['ios'][3] }}</span>
    </div>
    <div class="cancel" @click="closeSave"><img :src="require('./assets/close.svg')" /></div>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted, watch } from "vue";
import axios from "axios";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Autoplay]);

import "swiper/swiper-bundle.min.css";

export default {
  name: "App",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const selectedLang = ref('cn');
    const textArr = ref({
      'cn' : {
        'cs' : ['客服线路一', '客服线路二', '刷新'],
        'header' : ['客服'],
        'note' : ['支付宝安全存提款体验'],
        'gold' : ['标准奖金', '元', '送', '总奖金'],
        'ip' : ['请点击下方推荐线路进入页面', '点击查询IP', '您的IP为'],
        'list' : ['竞彩跟单快速兑奖通道', '看球玩球直播加奖通道', '首存100送1000申请通道', '高速访问', '线路', '推荐', '最快', '普通', '侦测网路中...'],
        'ios' : ['点击', '选择', '添加到主屏幕', '放至手机主屏，一键打开金球Gball']
      },
      'en' : {
        'cs' : ['CS line 1', 'CS Line 2', 'Refresh'],
        'header' : ['CS'],
        'note' : ['Deposit and Withdraw with Alipay'],
        'gold' : ['Standard Payout', 'CNY', 'Free', 'Total Payout'],
        'ip' : ['Choose any Gball high-speed connection', 'Click to check your IP', 'Your IP is'],
        'list' : ['竞彩跟单快速兑奖通道', 'Bet with MC to have boost odds', '100% Bonus First Deposit', 'High-speed connection', 'Line', 'Recommand', 'Fastest', 'Standard ', 'Searching connection for you......'],
        'ios' : ['Click', 'Choose', 'Add to Home Screen', 'Add Gball icon to your phone']
      },
      'id' : {
        'cs' : ['CS Baris 1', 'CS Baris 2', 'Perbaharui'],
        'header' : ['CS'],
        'note' : ['Deposit dan Penarikan dengan Alipay'],
        'gold' : ['Standar Pembayaran', 'CNY', 'Percuma', 'Total Pembayaran'],
        'ip' : ['Pilih koneksi Gball dengan kecepatan tinggi', 'Klik untuk melihat IP Anda', 'IP Anda adalah'],
        'list' : ['竞彩跟单快速兑奖通道', '看球玩球直播加奖通道', 'Bonus 100% Deposit Pertama', 'Koneksi kecepatan tinggi', '线路', 'Rekomendasi', 'Tercepat', 'Standar ', 'Mencari koneksi untuk Anda...'],
        'ios' : ['Klik', 'Pilih', 'Tambah pada Halaman Utama', 'tambah Gball pada HP Anda']
      }
    })
    const specialDomain = [
      ["localhost:8080"],
      ["jinqiu009", "jinqiu010"],
      ["jinqiu008"],
    ];
    const domainMap = [
      ["jqty11.com", "jqty22.com", "jqty33.com", "jqty44.com", "jqty44.com"],
      ["JQTY55.com", "jqty66.com", "jqty77.com", "jqty88.com", "jqty22.com"],
      [
        "JQTY55.com/streamer/live",
        "jqty66.com/streamer/live",
        "jqty77.com/streamer/live",
        "jqty88.com/streamer/live",
        "jqty22.com/streamer/live",
      ],
    ];
    const selectedLangData = ref({
      'name' : '中国',
      'value': '简体中文'
    });
    const selectedCountry = ref('cn');
    const selectedCode = ref('512');
    const showIP = ref(false);
    const showCs = ref(false);
    const isIOS = ref(false);
    const csData = ref(null);
    const videoSource = ref("");
    const imgSource = ref("");
    const bImgSource = ref("");
    const ip = ref(null);
    const sort = ref(true);
    const fontColor = ref("black");
    const urls = ref([
      "gb7188.com",
      "gbb18.com",
      "99gb.vip",
      "66gb.vip",
      "88gb.vip",
    ]);
    const changeDomain = () => {
      specialDomain.forEach((arr, i) => {
        console.log(location.host.split(".")[1]);
        const bool = arr.includes(location.host.split(".")[1]);
        if (bool) {
          urls.value = domainMap[i];
        }
      });
    };
    const domainList = computed(() => [
      {
        id: 1,
        ping: "",
        domain: urls.value[0],
      },
      {
        id: 2,
        ping: "",
        domain: urls.value[1],
      },
      {
        id: 3,
        ping: "",
        domain: urls.value[2],
      },
      {
        id: 4,
        ping: "",
        domain: urls.value[3],
      },
      {
        id: 5,
        ping: "",
        domain: urls.value[4],
      },
    ]);

    const newRedirectLogic = [
      {
        'url' : ['jinqiu001.com', 'jinqiu002.com', 'jinqiu003.com', 'jinqiu004.com', 'jinqiu005.com', 'jinqiu006.com', 'jinqiu008.com', 'jinqiu009.com', 'jinqiu010.com'],
        'redirect' : ['jinqiu111.com', 'jinqiu444.com', 'jinqiu555.com', 'jinqiu777.com', 'jinqiu999.com']
      },
      {
        'url' : ['jinqiu818.com', 'gbb78.com', 'gbvvip.com', 'qiubz.com', 'ssports24.com', 'ssports365.com', 'gball.live'],
        'redirect' : ['jqty11.com', 'jqty44.com', 'jqty55.com', 'jqty77.com', 'jqty99.com']
      }
    ];

    const best = computed(() => {
      return domainList.value.reduce((p, v) => {
        return p.ping < v.ping ? p : v;
      }).id;
    });

    const limit = [1000, 2000, 3000, 4000];

    onMounted(() => {
      selectedLang.value = localStorage.getItem('lang') || 'cn';
      let name = localStorage.getItem('name') || '中国';
      let value = localStorage.getItem('value') || '简体中文';
      selectedLangData.value = {
        'name' : name,
        'value': value
      }

      changeDomain();

      let checkLS = window.sessionStorage.getItem('save') || '';
      if(checkLS === '')
      {
        checkDevice();
      }

      fetch(`https://static.demoapi88.com/images/customerservice.json`, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
          },
      })
      .then(response => response.json())
      .then(response => {
          csData.value = JSON.parse(JSON.stringify(response));
      })

    });

    watch(domainList, () => {
      getPings();
    });

    const refresh = () => {
      location.reload(true);
    };

    const getIp = () => {
      // const t = setInterval(() => {
      //   if (window.returnCitySN) {
      //     ip.value = window.returnCitySN.cip;
      //     clearInterval(t);
      //   }
      // }, 500);
      var ipAddress = require('what-is-my-ip-address');
      ipAddress.v4()
        .then((result) => {
          ip.value = result;
        })
        .catch((error) => {
          console.warn(error)
          if (window.returnCitySN) {
            ip.value = window.returnCitySN.cip;
          }
        });
    };

    const getPings = () => {
      return new Promise((resolve) => {
        let doneList = [];
        domainList.value.forEach((item) => {
          let ping = null;
          const startTime = new Date();
          axios
            .get(`https://${isPC() ? "www" : "m"}.${item.domain}`)
            .finally(() => {
              const endTime = new Date();
              ping = Math.abs(endTime.getTime() - startTime.getTime());
              item.ping = ping;
              console.log("ean", startTime, endTime);
              sortList();
              doneList.push(item.domain);
              if (doneList.length === domainList.value.length) {
                resolve();
              }
            });
        });
      });
    };

    const openIP = () =>
    {
      showIP.value = true;
    }

    const getImg = (p) => {
      if (p) {
        return require("./assets/bottom-trans.png");
      }
      return require("./assets/ticket-top.svg");
    };

    const getPingsCSS = (val) => {
      if (typeof val !== "number") {
        return "";
      }
      if (val <= limit[0]) {
        return "ping h";
      } else if (val > limit[0] && val <= limit[1]) {
        return "ping m";
      } else if (val > limit[1] && val <= limit[2]) {
        return "ping l";
      } else if (val > limit[2]) {
        return "ping n";
      }
    };

    const goFast = () => {
      openDomain(domainList.value[0]);
    };

    const getFastStr = (val) => {
      if (typeof val !== "number") {
        return "";
      }
      if (val <= limit[0]) {
        return "最快";
      } else if (val > limit[0] && val <= limit[1]) {
        return "普通";
      } else if (val > limit[1] && val <= limit[2]) {
        return "慢";
      } else if (val > limit[2] && val <= limit[3]) {
        return "";
      } else if (val > limit[3]) {
        return "";
      }
    };

    const getCodeColor = (val) => {
      if (typeof val !== "number") {
        return "";
      }
      if (val <= limit[0]) {
        return "color-success";
      } else if (val > limit[0] && val <= limit[1]) {
        return "color-blue";
      } else if (val > limit[1] && val <= limit[2]) {
        return "color-warning";
      } else if (val > limit[2] && val <= limit[3]) {
        return "color-danger";
      } else if (val > limit[3]) {
        return "color-red";
      }
    };

    const checkDevice = () => {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        isIOS.value = true;
      } else {
        isIOS.value = false;
      }
    };
    const closeSave = () => {
      window.sessionStorage.setItem('save', 'true');
      isIOS.value = false;
    }

    var isPC = () => {
      var userAgentInfo = navigator.userAgent.toLowerCase();
      var Agents = new Array(
        "android",
        "iphone",
        "symbianOS",
        "windows phone",
        "ipad",
        "ipod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    };

    const openDomain = (item) => {
      const url = `https://${isPC() ? "www" : "m"}.${item.domain}`;
      window.open(url);
    };

    const openNewDomain = (index, item) =>
    {
      const url = window.location.origin;
      const firstIndex = newRedirectLogic[0].url.findIndex(x => url.includes(x));
      const secondIndex = newRedirectLogic[1].url.findIndex(x => url.includes(x));
      if(firstIndex !== -1 && secondIndex === -1)
      {
        let redirect = newRedirectLogic[0].redirect[index];
        let urltext = `https://${isPC() ? "www" : "m"}.${redirect}`;
        window.open(urltext);
      }
      else if(firstIndex === -1 && secondIndex !== -1)
      {
        let redirect = newRedirectLogic[1].redirect[index];
        let urltext = `https://${isPC() ? "www" : "m"}.${redirect}`;
        window.open(urltext);
      }
      else
      {
        openDomain(item);
      }
    };

    const sortList = () => {
      domainList.value.sort((a, b) =>
        sort.value ? a.ping - b.ping : b.ping - a.ping
      );
      sort.value = !sort.value;
    };

    const openCS = (boolean) => {
      showCs.value = boolean;
    };

    const selectLang = (data, id) => {
      let dataObj = {
        'name' : '',
        'value': ''
      };
      let index = data.data.findIndex(x => x.id === id);
      if(index > -1)
      {
        dataObj.value = data.data[index].value;
      }
      
      if(id == 1)
      {
        selectedLang.value = 'en';
        dataObj.name = data.en;
      }
      else if(id == 512)
      {
        selectedLang.value = 'cn';
        dataObj.name = data.cn;
      }
      else
      {
        selectedLang.value = 'id';
        dataObj.name = data.en;
      }

      selectedLangData.value = dataObj;
      
      localStorage.setItem('lang', selectedLang.value);
      localStorage.setItem('name', dataObj.name);
      localStorage.setItem('value', dataObj.value);
      window.location.reload();
    };

    const goCs = (num) =>
    {
      if(num === 1)
      {
        if(csData.value)
        {
          window.open(csData.value.line1);
        }
        else
        {
          window.open(`https://secure.livechatinc.com/licence/14229096/v2/open_chat.cgi?groups=0`);
        }
      }
      else
      {
        if(csData.value)
        {
          window.open(`${csData.value.line2.main}&pagereferrer=${window.location.origin}`);
        }
        else
        {
          window.open(
          `https://sewiow.x8omjk5u.com/chatwindow.aspx?siteId=60001518&planId=88d43332-b7b8-4ff6-9752-e771a6ab3cb3&pagereferrer=${window.location.origin}`
          ); 
        }
      }
    };
    const randomCs = () =>
    {
      if(csData.value)
      {
        let length = csData.value.line2.sub.length;
        let index = Math.floor(Math.random() * length);
        window.open(`${csData.value.line2.sub[index]}&pagereferrer=${window.location.origin}`);

      }
      else
      {
        window.open(
        `https://sewiow.x8omjk5u.com/chatwindow.aspx?siteId=60001518&planId=88d43332-b7b8-4ff6-9752-e771a6ab3cb3&pagereferrer=${window.location.origin}`
        ); 
      }
    };

    const safariHacks = () => {
      let windowsVH = window.innerHeight / 100;
      document
        .querySelector("#app")
        .style.setProperty("--vh", windowsVH + "px");
      window.addEventListener("resize", function() {
        document
          .querySelector("#app")
          .style.setProperty("--vh", windowsVH + "px");
      });
    };

    safariHacks();

    getPings().then(() => {
      getIp();

      setTimeout(() => {
        videoSource.value =
          "https://static.demoapi88.com/images/video_20221213.mp4";
      }, 1000);
      setTimeout(() => {
        fontColor.value = "white";
      }, 1000);

      setTimeout(() => {
        imgSource.value = getImg();
        bImgSource.value = getImg("b");
      }, 500);
    });

    return {
      selectedLang,
      textArr,
      selectedLangData,
      selectedCountry,
      selectedCode,
      fontColor,
      videoSource,
      imgSource,
      bImgSource,
      domainList,
      ip,
      showIP,
      showCs,
      best,
      getPingsCSS,
      getCodeColor,
      openDomain,
      openNewDomain,
      getPings,
      sortList,
      getFastStr,
      limit,
      openCS,
      selectLang,
      goCs,
      randomCs,
      goFast,
      isIOS,
      csData,
      swiper_options: reactive({
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        pagination: {
          clickable: true,
        },
      }),
      fakeData: [
        {
          league: "欧洲冠军联赛",
          team1: "皇家马德里",
          team2: "多特蒙德",
          auth: "比分 4:2",
          method: "皇马4:2击败多特蒙德就中奖",
          sb: "1530",
          sb2: "2550",
          tb: "4080",
          sport: 1,
        },
        {
          league: "欧洲杯",
          team1: "德国",
          team2: "其他球队",
          auth: "获胜者 德国",
          method: "德国夺得2024欧洲杯就中奖",
          sb: "195",
          sb2: "325",
          tb: "520",
          sport: 1,
        },
        {
          league: "欧洲杯",
          team1: "法国",
          team2: "其他球队",
          auth: "获胜者 法国",
          method: "法国夺得2024欧洲杯就中奖",
          sb: "150",
          sb2: "250",
          tb: "400",
          sport: 1,
        },
        {
          league: "欧洲杯",
          team1: "英格兰",
          team2: "其他球队",
          auth: "获胜者 英格兰",
          method: "英格兰夺得2024欧洲杯就中奖",
          sb: "200",
          sb2: "120",
          tb: "320",
          sport: 1,
        },
      ],
      languageData : [
      {
        'img'    : 'cn',
        'cn' : '中国',
        'en' : 'China',
        'index'  : 1,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '512',
            'value': '简体中文',
            'lang' : 'zh-CN'
          },
          {
            'id'   : '',
            'value': '繁体中文',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'en',
        'cn' : '英国',
        'en' : 'UK',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          }
        ]
      },
      {
        'img' : 'idn',
        'cn' : '印尼',
        'en' : 'Indonesia',
        'index'  : 1,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '7',
            'value': 'B. Indonesia',
            'lang' : 'id-ID'
          }
        ]
      },
      {
        'img' : 'hk',
        'cn' : '香港',
        'en' : 'Hong Kong',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '512',
            'value': '简体中文',
            'lang' : 'zh-CN'
          },
          {
            'id'   : '',
            'value': '繁体中文',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'th',
        'cn' : '泰国',
        'en' : 'Thailang',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'แบบไทย',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'ph',
        'cn' : '菲律宾',
        'en' : 'Philippines',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Tagalog',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'my',
        'cn' : '马来西亚',
        'en' : 'Malaysia',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '512',
            'value': '简体中文',
            'lang' : 'zh-CN'
          },
          {
            'id'   : '',
            'value': 'B. Melayu',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'vn',
        'cn' : '越南',
        'en' : 'Veitnam',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Tiếng Việt',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'it',
        'cn' : '意大利',
        'en' : 'Italy',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Italiano',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'gm',
        'cn' : '德国',
        'en' : 'German',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Deutsch',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'fr',
        'cn' : '法国',
        'en' : 'French',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Français',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'au',
        'cn' : '澳大利亚',
        'en' : 'Australia',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          }
        ]
      },
      {
        'img' : 'jp',
        'cn' : '日本',
        'en' : 'Japan',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': '日本語',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'kr',
        'cn' : '韩国',
        'en' : 'Korea',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': '한국인',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'pt',
        'cn' : '葡萄牙',
        'en' : 'Portugal',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Português',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'br',
        'cn' : '巴西',
        'en' : 'Brasil',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Português',
            'lang' : ''
          }
        ]
      },
      {
        'img' : 'sd',
        'cn' : '瑞典',
        'en' : 'Sweden',
        'index'  : 0,
        'data'   : [
          {
            'id'   : '1',
            'value': 'English',
            'lang' : 'en-US'
          },
          {
            'id'   : '',
            'value': 'Svenska',
            'lang' : ''
          }
        ]
      }
    ],
      closeSave,
      refresh,
      openIP
    };
  },
};
</script>

<style>
.top {
  background-size: 100%;
  height: 69px;
  background-position: bottom;
}
.bottom {
  background-size: 100%;
  height: 59px;
}
.ping {
  display: flex;
  margin-left: 20px;
  min-width: 70px;
}
.ping div {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin: 0 2px 0 0;
}
.ping.h div {
  background-color: #00de33;
}
.ping.m div {
  background-color: #ffcb3e;
}
.ping.l div {
  background: #ff553e;
}
.sb2 {
  color: #ff553e;
  font-size: 14px;
}
</style>
